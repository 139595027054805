<template>
  <v-tooltip
      :open-delay="500"
      :color="color"
      bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div
          v-on="on"
          v-bind="attrs"
      >
        <slot></slot>
      </div>
    </template>
    <span>{{ textToolTip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: {textToolTip: String, color: String},
};
</script>
