<template>
  <v-card
    class="pb-6 backgroundColor"
    height="100%"
  >
    <v-btn
      name="Retour"
      class="ma-6"
      text
      icon
      color="primary"
      @click="backTo()"
    >
      <v-icon size="60px">mdi-arrow-left-circle-outline</v-icon>
    </v-btn>
    <div class="Welcometitle primary--text titleFontlg">
      Modification du compte : <span class="secondary--text">{{user.identifiant}} </span>
      <span class="primary--text"> {{ user.prenom }} {{ user.nom }} </span>
    </div>
    <div class="subtitle-account" v-if="user.role === 'PROF' || user.role === 'ELEVE'">
      <span class="primary--text">Année scolaire : {{ user.anneeScolaire }} </span>
    </div>
    <ModifieUserInfo v-if="user.etablissements" :user="user" />
    <v-skeleton-loader v-else></v-skeleton-loader>
  </v-card>
</template>

<script>
import ModifieUserInfo from "../components/UserIdVueComponent/ModifieUserInfo.vue";
import AdminService from "./../services/AdminService";

export default {
  components: {
    ModifieUserInfo,
  },
  data() {
    return {
      gotolibrarybtn: true,
      loadingtab: "",
      books: [],
      user: {
        identifiant: "",
        nom: "",
        prenom: "",
        emailContact: "",
        codeAdherent: "",
        role: "",
        actif: true,
        etablissement: "",
        classes: "",
        soutienBordas: false,
        backBTNColor: "primary",
      },
    };
  },
  mounted() {
    AdminService.getUser(this.$route.params.id)
      .then((res) => {
        this.user = res.data;
        if(this.user.role === 'ELEVE') {
          this.user.classes = this.user.classes[0]
        }
        res.data.bibliotheque.forEach((book) => {
          this.books.push(book.ouvrage);
        });
        this.loadingtab = "false";
      })
      .catch((error) => console.log(error));
  },
  methods: {
    initialize() {
      AdminService.getUser(this.$route.params.id)
        .then((element) => (this.books = element.data))
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        });
    },
    backTo() {
      this.$router.push({ path: `/admin/users` }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
};
</script>
 <style scoped>
 .subtitle {
   text-align: center;
   margin-bottom: 16px;
   color: #034155;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   font-size: 24px;
 }
 .subtitle-account {
   text-align: center;
   color: #034155;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   font-size: 15px;
 }
.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.titleFontlg {
  font-size: 28px;
}
</style>
