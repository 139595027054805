import http from '/http-common';

async function fetchCurrentAnneeInfo() {
  return http.get('/annee');
}

const AnneeService = {
  fetchCurrentAnneeInfo,
};

export default AnneeService;
