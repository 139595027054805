<template>
  <v-dialog
      v-model="status"
      max-width="600px"
      persistent
      @click:outside="emitCancel()"
  >
    <v-card>
      <v-card-title class="text-h5 text-break">
        {{ text }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            name="Annuler"
            color="secondary darken-1"
            text
            @click="emitCancel"
        >annuler
        </v-btn>
        <v-btn
            name="Confirmer"
            color="green darken-1"
            text
            @click="emitValidation"
            :loading="loading"
        >confirmer
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NextButton",
  props: ["text", "status", "loading"],
  methods: {
    emitValidation() {
      this.$emit("validated");
    },
    emitCancel() {
      this.$emit("Canceled");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
