<template>
  <v-col v-if="!fetchingYear" cols="10" offset="1">
    <v-row>
      <v-col cols="12" sm="2" class="d-flex pl-4">
        <Tooltip textToolTip="Réinitialiser le mot de passe.">
          <v-btn name="Réinitialiser le mots de passe" icon color="primary" class="mx-2">
            <v-icon large @click="resetPass()">mdi-lock-reset</v-icon>
          </v-btn>
        </Tooltip>
        <Tooltip textToolTip="Supprimer l'utilisateur.">
          <v-btn name="Supprimer" icon color="secondary">
            <v-icon large @click="deleteUser()"
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'">mdi-delete</v-icon>
          </v-btn>
        </Tooltip>
        <Tooltip textToolTip="Statistiques de l'utilisateur.">
          <v-btn name="Statistiques utilisateur" icon color="green" class="mx-2"
            v-if="user.role === `ELEVE` || user.role === `PROF`">
            <v-icon large @click="
              $router.push({
                path: `/admin/user/statistique/${user.identifiant}`,
              }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                  throw error;
                }
              });
            ">mdi-chart-line</v-icon>
          </v-btn>
        </Tooltip>
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="2" class="d-flex justify-space-around pl-4">
        <v-btn name="Voir les manuels associés" color="primary" elevation="0" @click="goToLibrary()"
          v-if="user.role === `ELEVE` || user.role === `PROF`" class="mx-a" :outlined="switchIsHovered"
          @mouseover="hovered()" @mouseleave="notHovered()">Voir les manuels associés
          <v-icon class="ml-2">mdi-bookshelf</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!--FORM-->
    <v-card elevation="1">
      <v-form class="px-5 pt-7 my-4" ref="form" v-model="valid" lazy-validation
        :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'">
        <!--ligne 0-->
        <v-row>
          <v-col cols="10" sm="3" class="pb-0">
            <v-switch v-model="user.actif" inset :label="labelActif" />
          </v-col>
        </v-row>
        <!--ligne 1-->
        <v-row class="justify-sm-start justify-center">
          <v-col cols="10" :sm="user.role === `GESTIONNAIRE_ARBS` ? `12` : `4`" class="pb-0">
            <v-select :items="roles" dense v-model="user.role" filled label="Role *" @change="resetRole"
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"></v-select>
          </v-col>
          <v-col cols="10"
            v-if="user.role !== `GESTIONNAIRE_ARBS` && user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' && user.role !== 'PROF'"
            :sm="user.role === `GESTIONNAIRE_ARBS` || user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' ? `6` : `4`"
            class="pb-0">
            <v-text-field v-model="user.codeAdherent" label="ID Parent *" dense filled
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'" />
          </v-col>
          <v-col cols="10"
            :sm="user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' || user.role === 'PROF' ? 8 : 4"
            class="pb-0"
            v-if="user.role !== `GESTIONNAIRE_ARBS` && user.role !== 'GESTIONNAIRE_ETABLISSEMENT' || (user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE')">
            <v-autocomplete v-model="selectedEtablissement" item-text="id" return-object
              :multiple="user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE` || user.role === `GESTIONNAIRE_ARBS`"
              :items="etablissements" attach dense filled :hint="user.infoEtab" persistent-hint label="Etablissement *"
              v-if="user.role === `PROF` || user.role === `ELEVE` || user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
              :menu-props="{ top: true, offsetY: true }"
              :rules="[() => selectedEtablissement.length === 0 ? 'Ce champ est requis' : true]" required
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"></v-autocomplete>
          </v-col>
        </v-row>
        <!--ligne 2-->
        <v-row class="justify-center justify-sm-start">
          <v-col cols="10"
            :sm="user.role === `GESTIONNAIRE_ARBS` || user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' ? `6` : `4`"
            class="pb-0">
            <v-text-field v-model="user.nom" label="Nom *" dense filled :rules="[
              () => !!user.nom || 'Ce champ est requis',
              () => user.nom.length < 35 || 'nombre de caractère maximum atteint',
            ]" :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'" />
          </v-col>
          <v-col cols="10"
            :sm="user.role === `GESTIONNAIRE_ARBS` || user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' ? `6` : `4`"
            class="pb-0">
            <v-text-field v-model="user.prenom" label="Prénom *" dense filled :rules="[
              () => !!user.prenom || 'Ce champ est requis',
              () => user.prenom.length < 35 || 'nombre de caractère maximum atteint',
            ]" :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'" />
          </v-col>
          <v-col cols="10" sm="4" v-if="user.role === `ELEVE` || user.role === `PROF`" class="pb-0 my-1">
            <v-select v-model="selectedClasses" :items="classes" attach chips dense label="Classe *" class="pa-1"
              :menu-props="{ top: true, offsetY: true }" :rules="[() => !!selectedClasses || 'Ce champ est requis']"
              required v-if="user.role === `ELEVE`" :loading="!classesArrayLoading && !!user.etablissement"
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"></v-select>
            <v-select v-model="selectedClasses" :items="classes" attach chips label="Classes *" multiple dense
              v-if="user.role === `PROF`" class="pa-1" :menu-props="{ top: true, offsetY: true }"
              :rules="[() => !!selectedClasses.length || 'Ce champ est requis']"
              :loading="!classesArrayLoading && !!user.etablissement" required
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"></v-select>
          </v-col>
        </v-row>
        <!--ligne3-->
        <v-row class="justify-center justify-sm-start">
          <v-col cols="10"
            :sm="user.role === `GESTIONNAIRE_ARBS` || user.role === 'GESTIONNAIRE_ETABLISSEMENT' || user.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' ? `12` : `8`"
            class="pb-0">
            <v-text-field v-model="user.emailContact" label="Email *" dense filled
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'" />
          </v-col>
          <v-col cols="10" sm="3" class="pb-0 mx-0">
            <v-switch v-model="user.soutienBordas"
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"
              inset
              v-if="user.role !== `GESTIONNAIRE_ARBS` && user.role !== 'GESTIONNAIRE_ETABLISSEMENT' && user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE' && user.role !== `PROF`">
              <template v-slot:label>
                <span class="input__label">Accès CAPEEZI</span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row class="justify-center justify-sm-start">
          <v-col cols="10" sm="4" class="pb-0">
            <v-select :items="anneesScolaires" dense v-model="user.anneeScolaire" filled label="Année scolaire *"
              :disabled="userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT' || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'"></v-select>
          </v-col>
        </v-row>
        <v-row :justify="$vuetify.breakpoint.xs ? 'center' : 'end'" class="px-3 py-6">
          <NextButton width="280" color="primary" text="Appliquer les modifications" @clicked="sendAccountModif()"
            :loading="loading" :disable="!valid || userConnected.role === 'GESTIONNAIRE_ETABLISSEMENT'" class="mx-a" />
        </v-row>
      </v-form>
      <!-- Modal DELETE USER---------------------------------------------------------------------------->
      <ValidationModal text="Êtes-vous sûr de bien vouloir supprimer cet utilisateur?" :status="dialogDeleteUser"
        @validated="deleteUserConfirm()" @Canceled="closeDeleteUser()" />
      <!-- Modal RESET ----------------------------------------------------------------------------->
      <ValidationModal text="Réinitialiser le mot de passe de cet utilisateur ?" :status="dialogReset"
        @validated="resetItemConfirm()" @Canceled="closeReset()" />
    </v-card>
  </v-col>
  <v-row align="center" style="height: 80vh" justify="center" v-else>
    <v-progress-circular size="100" color="#fb4d61" indeterminate></v-progress-circular>
  </v-row>
</template>

<script>
import NextButton from "../Utility/NextButton.vue";
import AdminService from "../../services/AdminService";
import ForgotPWService from "../../services/ForgotPWService";
import ValidationModal from "../Utility/ValidationModal.vue";
import Tooltip from "../Utility/Tooltip.vue";
import AnneeService from "@/services/AnneeService.js";


export default {
  data() {
    return {
      selectedClasses: undefined,
      switchIsHovered: true,
      loading: false,
      valid: false,
      dialogReset: false,
      dialogDeleteUser: false,
      roles: ["ELEVE", "GESTIONNAIRE_ARBS", "GESTIONNAIRE_ETABLISSEMENT_AVANCE", "GESTIONNAIRE_ETABLISSEMENT", "PROF"],
      etablissements: [],
      classes: [],
      classesArrayLoading: true,
      anneesScolaires: [""],
      selectedEtablissement: [],

      fetchingYear: true,
    };
  },
  components: {
    NextButton,
    ValidationModal,
    Tooltip,
  },
  props: ["user"],
  computed: {
    labelActif() {
      if (this.user.actif == true) {
        return "Activé";
      } else {
        return "Désactivé";
      }
    },
    userConnected() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.etablissementArray();
    this.initSchoolYear();
    if (this.user.etablissements) {
      if (["PROF", "ELEVE"].includes(this.user.role)) {
        this.selectedEtablissement = this.user.etablissements[0].id;
        this.selectedClasses = this.user.classes
      } else {
        this.selectedEtablissement = this.user.etablissements.id;
      }
      if (this.user.etablissements.length > 0 && (this.user.role === 'PROF' || this.user.role === 'ELEVE')) {
        // Not multiple autocomplete cannot have a list as value
        this.user.etablissements = this.user.etablissements[0];
      }
    }
  },
  methods: {
    etablissementArray() {
      if (!this.etablissements.length) {
        AdminService.getEtablissementIds().then(({ data }) => {
          data.forEach((etablissement) => {
            this.etablissements.push(`${etablissement.id}`);
          });
        });
      }
    },
    initSchoolYear() {
      AnneeService.fetchCurrentAnneeInfo().then(anneeInfo => {
        const anneeScolaire = anneeInfo.data.scolaire;
        this.anneeScolaire = anneeScolaire.code
        this.anneesScolaires = [anneeScolaire.code + 101, this.anneeScolaire, anneeScolaire.code - 101]
      }).catch(() => {
        this.anneeScolaire = parseInt(process.env.VUE_APP_DEFAULT_SCHOOL_YEAR)
        this.anneesScolaires = [this.anneeScolaire + 101, this.anneeScolaire, this.anneeScolaire.code - 101]
      }).finally(() => {
        this.fetchingYear = false
      })
    },
    classesArray(id) {
      this.loading = true;
      this.classesArrayLoading = false;
      this.classes = [];
      AdminService.getClasseByID(id).then(({ data }) => {
        data.classes.forEach((classe) => {
          this.classes.push(classe.name);
        });
        this.classesArrayLoading = true;
        this.loading = false;
      });
    },
    hovered() {
      this.switchIsHovered = false;
      this.boutonMove = "pr-1";
    },
    notHovered() {
      this.switchIsHovered = true;
      this.boutonMove = "pr-4";
    },
    goToLibrary() {
      this.$router.push({
        path: `/admin/users/${this.user.identifiant}/manuels`,
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    resetPass() {
      this.dialogReset = true;
    },
    deleteUser() {
      this.dialogDeleteUser = true;
    },
    sendAccountModif() {
      this.loading = true;
      try {
        this.user.etablissements = this.selectedEtablissement;
        this.user.classes = this.selectedClasses;
        // If the value isn't an array, convert it
        if (!Array.isArray(this.user.etablissements)) {
          this.user.etablissements = [this.user.etablissements]
        }
        if(!Array.isArray(this.user.classes)) {
          this.user.classes = [this.user.classes]
        }
        AdminService.updateUser(this.user)
          .then(() => {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "L'utilisateur a été mis à jour.",
            });
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "L'utilisateur n'a pas pu être mis à jour.",
            });
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "L'utilisateur n'a pas pu être mis à jour.",
        });
        this.loading = false;
      }
    },
    closeDeleteUser() {
      this.dialogDeleteUser = false;
    },

    deleteUserConfirm() {
      AdminService.deleteUser(this.user).then(() => {
        this.closeDeleteUser();
        this.$router.push({ name: "DashboardAdmin" }).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      });
    },
    resetItemConfirm() {
      ForgotPWService.postSendEmail(this.user.identifiant);
      this.closeReset();
    },
    closeReset() {
      this.dialogReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    resetRole() {
      this.user.classes = [];
      this.valid = false;
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogReset(val) {
      val || this.closeReset();
    },
    "user.role": function () {
      this.valid = true;
    },
    "selectedEtablissement": function () {
      if (this.selectedEtablissement) {
        this.user.etablissments = this.selectedEtablissement;
        let etbId = undefined
        if (this.user.role === 'ELEVE' || this.user.role === 'PROF') {
          this.user.classes = [];
          this.classesArray(this.selectedEtablissement, this.user.role);
        }
        if(etbId) {
          AdminService.getEtablissementByID(etbId).then(
            ({ data }) => {
              this.user.infoEtab = `${data.ville} - ${data.nom}`;
            }
          );
        }
      }
    },
    deep: true,
  },
};
</script>

<style lang="scss">
.input__label {
  white-space: nowrap;
}
</style>
